<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.457 22.514c6.107 0 11.057-4.95 11.057-11.057S17.564.4 11.457.4.4 5.35.4 11.457s4.95 11.057 11.057 11.057z" stroke="#000" stroke-miterlimit="10" stroke-linecap="round"/><path d="M6.924 8.041l-2.021 4.043" stroke="#000" stroke-miterlimit="10"/><path d="M7.419 8.039l2.021 4.045z" fill="#fff"/><path d="M7.419 8.039l2.021 4.045" stroke="#000" stroke-miterlimit="10"/><path d="M4.903 12.084v.18a2.27 2.27 0 104.537 0v-.18H4.903z" fill="#fff" stroke="#000" stroke-linejoin="round"/><path d="M15.99 8.041l2.021 4.043z" fill="#fff"/><path d="M15.99 8.041l2.021 4.043" stroke="#000" stroke-miterlimit="10"/><path d="M15.495 8.039l-2.021 4.045z" fill="#fff"/><path d="M15.495 8.039l-2.021 4.045" stroke="#000" stroke-miterlimit="10"/><path d="M18.011 12.084v.18a2.27 2.27 0 11-4.537 0v-.18h4.537z" fill="#fff" stroke="#000" stroke-linejoin="round"/><path d="M5.407 7.294c.053.279.873.756 1.551.756 2.327 0 2.172-1.512 4.5-1.512" fill="#fff"/><path d="M5.407 7.294c.053.279.873.756 1.551.756 2.327 0 2.172-1.512 4.5-1.512" stroke="#000" stroke-miterlimit="10" stroke-linecap="round"/><path d="M17.507 7.294c-.053.279-.873.756-1.551.756-2.327 0-2.172-1.512-4.5-1.512" fill="#fff"/><path d="M17.507 7.294c-.053.279-.873.756-1.551.756-2.327 0-2.172-1.512-4.5-1.512" stroke="#000" stroke-miterlimit="10" stroke-linecap="round"/><path d="M11.457 5.53v10.587z" fill="#fff"/><path d="M11.457 5.53v10.587" stroke="#000" stroke-miterlimit="10" stroke-linecap="round"/><path d="M11.457 16.117c-.5.5-2.521.5-2.521.5z" fill="#fff"/><path d="M11.457 16.117c-.5.5-2.521.5-2.521.5" stroke="#000" stroke-miterlimit="10" stroke-linecap="round"/><path d="M11.457 16.117c.5.5 2.521.5 2.521.5z" fill="#fff"/><path d="M11.457 16.117c.5.5 2.521.5 2.521.5" stroke="#000" stroke-miterlimit="10" stroke-linecap="round"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconLegal'
  };
</script>

<template>
  <div class="terms">
    <Banner>
      <div class="banner-content">
        <MeepIconLegal
          class="banner-content__icon banner-content__icon-sub-inverted"
        />
        <span class="banner-content__text">{{ $t("term.banner") }}</span>
      </div>
    </Banner>
    <div class="terms-content page-layout">
      <div class="md-alignment-center-center">
        <md-card class="terms__card md-layout-item md-size-80">
          <PageHeader
            :title="$t('term.title')"
            :has-back="true"
            @back="backToHome"
          />

          <md-card-content>
            <p v-if="isGC">
              <b>Nom du site :</b> groupeconseils.meep-appli.fr/
            </p>
            <p v-else><b>Nom du site :</b> v2.meep-appli.fr/</p>

            <p>
              <b>Directeur de la publication et responsable technique :</b>
              Sabine SAMAK
            </p>
            <p>
              <b>Crédit photographique :</b> Expert SA. Toute reproduction est
              interdite sauf accord express et écrit de Expert SA.
            </p>
            <p><b>Adresse postale :</b> 17 rue du Niger, 75012 Paris</p>
            <p><b>Capital social: 500 000 €</b> - RCS Paris 419 332 101</p>
            <p><b>TVA intracom :</b> FR17419332101</p>
            <p><b>Tél :</b> 0820 020 330</p>
            <p><b>Créateur, Editeur et hébergement :</b> Expert SA</p>
            <p></p>

            <h3 class="terms__title">{{ $t("term.first-title") }}</h3>
            <p>
              Les informations présentées sur ce site sont privées. La
              reproduction des pages de ce site est interdite. Elles ne peuvent
              être utilisées à des fins commerciales ou publicitaires.
            </p>
            <p>
              Lorsque des données présentes sur ce site ont un caractère
              nominatif, les utilisateurs doivent en faire un usage conforme aux
              règlementations en vigueur et aux recommandations de la Commission
              nationale de l'informatique et des libertés (CNIL).
            </p>
            <h3 class="terms__title">{{ $t("term.second-title") }}</h3>
            <p>
              Loi du 1er août 2000 relative à la liberté de communication
              (modifiant la loi du 30 septembre 1986):
            </p>
            <p>
              Le site Internet
              <span v-if="isGC">groupeconseils.meep-appli.fr</span>
              <span v-else>v2.meep-appli.fr</span> est édité et hébergé par OVH
              en France.
            </p>
            <h3 class="terms__title">{{ $t("term.third-title") }}</h3>
            <p>
              En vertu de la loi informatique et libertés du 6 janvier 1978,
              nous vous informons que les données à caractère nominatif
              recueillies auprès des internautes par l'intermédiaire d'un
              formulaire sur v2.meep-appli.fr/ sont la propriété stricte
              d’Expert SA. Vous disposez d’un droit d’accès et de modification
              des données nominatives vous concernant. Pour exercer ce droit,
              envoyez votre demande par courrier postal à Expert SA.
            </p>
            <h3 class="terms__title">{{ $t("term.fourth-title") }}</h3>
            <p>
              Sauf mention contraire signalée sur un texte, tous les éléments de
              ce site Internet (notamment les marques déposées, noms de domaine,
              les textes, graphismes, logos, sons, photos et animations) sont la
              propriété des éditeurs de ce site.
            </p>
            <p>
              La protection du site relève, par conséquent, des législations
              nationales et internationales relatives au droit de la propriété
              intellectuelle. Dès lors, toute reproduction et/ou représentation
              et/ou rediffusion, en tout ou partie, sur tout support
              électronique ou non, présent ou futur, sont interdites sauf
              autorisation expresse et préalable.
            </p>
            <p>
              Le non-respect de cette interdiction est susceptible de constituer
              un acte de contrefaçon engageant les responsabilités civile et
              pénale de tout contrevenant.
            </p>

            <p>
              De même, les bases de données d'informations sont protégées au
              titre du droit applicable aux compilations de données. De sorte
              que toute extraction ou tentative d'extraction, fut-elle totale ou
              partielle, est susceptible d'engager les responsabilités civile et
              pénale de tout contrevenant.
            </p>
            <h3 class="terms__title">{{ $t("term.fifth-title") }}</h3>

            <p>
              Nous vous informons que tout site Internet ou tout autre support
              souhaitant citer
              <span v-if="isGC">groupeconseils.meep-appli.fr</span>
              <span v-else>v2.meep-appli.fr</span> doit en demander
              l’autorisation auprès d’Expert SA.
            </p>
            <p>
              Au titre de cette autorisation, Expert SA se réserve un droit
              d'opposition.
            </p>
            <h3 class="terms__title">{{ $t("term.sixth-title") }}</h3>
            <p>
              Les informations contenues sur le site
              <span v-if="isGC">groupeconseils.meep-appli.fr</span>
              <span v-else>v2.meep-appli.fr</span>
              ne peuvent en aucun cas être assimilées à des prestations de
              services ou de conseil rendues par leurs auteurs ou éditeurs.
              Aussi, elles ne peuvent être utilisées comme un substitut à une
              consultation rendue par une personne professionnellement
              compétente.
            </p>
            <p>
              Expert SA n'est tenu que d'une simple obligation de moyens
              concernant les informations qu'il met à disposition des personnes
              qui accèdent à son site Web.
            </p>
            <p>
              Alors même que nous avons effectué toutes les démarches pour nous
              assurer de la fiabilité des informations contenues sur ce site
              Web, Expert SA ne peut encourir aucune responsabilité du fait
              d'erreurs, d'omissions, ou pour les résultats qui pourraient être
              obtenus par l'usage de ces informations. Notamment, l'usage de
              liens hypertextes peut conduire votre consultation de notre site
              vers d'autres serveurs pour prendre connaissance de l'information
              recherchée, serveurs sur lesquels Expert SA n'a aucun contrôle.
            </p>
            <h3 class="terms__title">{{ $t("term.seventh-title") }}</h3>
            <p>
              <span v-if="isGC">Groupe Conseils</span
              ><span v-else>Expert SA</span> est le propriétaire des droits,
              titres et intérêts liés au nom de
              <span v-if="isGC">Groupe Conseils</span
              ><span v-else>Expert SA</span>. Toute utilisation de ce nom est
              interdite sans l'autorisation préalable d’Expert SA. Ce site et
              son contenu sont protégés, au sens du code de la propriété
              intellectuelle, en particulier par les droits d'auteur, dessins et
              modèles et droits des marques.
            </p>
            <p>
              <span v-if="!isGC">MEEP est une marque déposée à l’INPI.</span>En
              application du Code français de la Propriété Intellectuelle et,
              plus généralement, des traités et accords internationaux
              comportant des dispositions relatives à la protection des droits
              d'auteurs, vous vous interdirez de reproduire pour un usage autre
              que privé, vendre, distribuer, émettre, diffuser, adapter,
              modifier, publier, communiquer intégralement ou partiellement,
              sous quelque forme que ce soit, les données, la présentation ou
              l'organisation du site ou les œuvres protégées par le droit
              d'auteur qui figurent sur le site Web
              <span v-if="isGC">groupeconseils.meep-appli.fr</span>
              <span v-else>v2.meep-appli.fr</span> sans autorisation écrite
              particulière et préalable du détenteur du droit d'auteur attaché à
              l'œuvre, à la présentation ou à l'organisation du site ou à la
              donnée reproduite. Vous vous interdirez également d'introduire des
              données sur le site Web
              <span v-if="isGC">groupeconseils.meep-appli.fr</span>
              <span v-else>v2.meep-appli.fr</span> qui modifieraient ou qui
              seraient susceptibles de modifier le contenu ou l'apparence des
              données, de la présentation ou de l'organisation du site ou des
              œuvres figurant sur le site Web de
              <span v-if="isGC">Groupe Conseils</span>
              <span v-else>MEEP</span> et par quelques procédés que ce soit.
            </p>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/PageHeader";
import { mapGetters } from "vuex";
import Banner from "@/components/Banner";
import MeepIconLegal from "@/components/icons/MeepIconLegal.vue";
import HasBackMixin from "@/mixins/HasBackMixin";

export default {
  name: "TermsOfUse",

  components: {
    PageHeader,
    Banner,
    MeepIconLegal,
  },

  mixins: [HasBackMixin],

  computed: {
    ...mapGetters(["isGC"]),
  },

  mounted() {
    this.$scrollTo(".terms__card");
  },
};
</script>

<style lang="scss" scoped>
.terms {
  &__card {
    border-radius: 10px;
    padding: 50px 40px;

    .md-card-content {
      font-size: 17px;
      line-height: 24px;
      padding: 0;
      b {
        font-family: var(--font-extrabold);
      }

      p,
      span {
        letter-spacing: -0.43px;
        font-family: var(--font-x);
      }
    }
  }

  &__title {
    color: var(--bg-primary);
    margin: 25px 0;
    font-size: 29px;
    font-family: var(--font-bold);
  }
}
</style>
